.warp {
  height: 100vh;
  width: 100%;
  background: linear-gradient(200deg, #eedede, #bfd0eb);
  display: flex;
  justify-content: center;
  align-items: center;
}


.login-warp {
  padding: 0 50px 50px 50px;
  background-color: #fff;
  border-radius: 20px;
}

.login-title{
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    line-height: 100px;
}
#form{
  width: 400px;
}