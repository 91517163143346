.toggle-warp{
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
}
.toggle-warp input {
    display: none;
}
.toggle-warp label {
    position: absolute;
    z-index: 999;
    padding: 1px;
    height: 24px;
    width: 50px;
    border-radius: 20px;
    background: #222;
    border: 3px solid #666;
    cursor: pointer;
    transition: all 0.8s cubic-bezier(1, 0, 0.5, 0);
}
.toggle-warp .switcher {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    vertical-align: middle;
}
.toggle-warp #on {
    transform: translateY(-1px);
    position: absolute;
    z-index: 2;
    background: #aea497;
    border: 2px solid gold;
    transition: all 500ms cubic-bezier(0.8, 0, 0.05, 1);
}
.toggle-warp .toggle:checked ~ label {
    background: #ddd;
    border: 2px solid white;
}
.toggle-warp .toggle:checked ~ label #on {
    background: gold;
    border: 2px solid #aea497;
    transform: translateX(100%);
    /* //////////////////////////////////////////////////////////
Then the switch ends, anything from here is just the cute background animation.
////////////////////////////////////////////////////////// */
    /* The background */
}
